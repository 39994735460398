@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:400,500,700|Montserrat:400,600,700|PT+Sans');
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary {display: block;}
audio,canvas,video {display: inline-block;}
audio:not([controls]) {display: none; height: 0;}
[hidden] {display: none;}
html {font-family: sans-serif; /* 1 */-ms-text-size-adjust: 100%; /* 2 */ -webkit-text-size-adjust: 100%; /* 2 */}
a:focus {outline: none !important;}
a:active,a:hover {outline: 0;}
h1 {font-size: 2em;margin: 0.67em 0;}
abbr[title] {border-bottom: 1px dotted;}
b,strong {font-weight: bold;}
dfn {font-style: italic;}
hr {-moz-box-sizing: content-box;box-sizing: content-box;height: 0;}
mark {background: #ff0;color: #000;}
code,kbd,pre,samp {font-family: monospace, serif;font-size: 1em;}
pre {white-space: pre-wrap;}
q {quotes: "\201C" "\201D" "\2018" "\2019";}
small {font-size: 80%;}
sub,sup {font-size: 75%;line-height: 0;position: relative; vertical-align: baseline;}
sup {top: -0.5em;}
sub {bottom: -0.25em;}
img {border:0; max-width:100%;}
a{text-decoration:none !important;}
svg:not(:root) {overflow: hidden;}
figure {margin: 0;}
fieldset {border: 1px solid #c0c0c0;margin: 0 2px;padding: 0.35em 0.625em 0.75em;}
legend {border: 0; /* 1 */ padding: 0; /* 2 */}
button,input,select,textarea {font-family: inherit; /* 1 */font-size: 100%; /* 2 */margin: 0; /* 3 */}
button,input {line-height: normal;}
button,select {text-transform: none;}
a{text-decoration:none;}
button,html input[type="button"], /*1*/input[type="reset"],input[type="submit"] {-webkit-appearance: button; /* 2 */ cursor: pointer; /* 3 */}
button[disabled],html input[disabled] {cursor: default;}
input[type="checkbox"],input[type="radio"] {box-sizing: border-box; /* 1 */ padding: 0; /* 2 */}
input[type="search"] {-webkit-appearance: textfield; /* 1 */-moz-box-sizing: content-box;-webkit-box-sizing: content-box; /* 2 */ box-sizing: content-box;}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {-webkit-appearance: none;}
button::-moz-focus-inner,
input::-moz-focus-inner {border: 0;padding: 0;}
textarea {overflow: auto; /* 1 */ vertical-align: top; /* 2 */}
table {border-collapse: collapse; border-spacing: 0;}
body{
  font-family: $bodyFontFamily;
  margin: 0;
  padding: 0;
  color: $bodyTextColor;
  background: #f3f3f3;
  color: #333;
}
.headerRs{
	margin: 0;
	padding:10px 0;
	background:#fff;
}
.headright{
	text-align: right;
	a{
		color: #000;
	}

}
.bannerBX{
	margin: 10px 0;
}
.socialicon {
	margin: 0;
	padding:10px 0;
	li{
	display: inline-block !important;
	}
}
.footBx1{
	li{
		display:inline-block !important;
		a{
			display:block;
			background:#333;
			padding:5px 10px !important;
			border-radius:4px ;
			margin: 3px;
			font-size: 14px;
			&:hover{
				background:#8b487d;
			}
		}
	}
}

/*=========================
  Icons
 ================= */

/* footer social icons */
// ul.social-network {
// 	list-style: none;
// 	display: inline;
// 	margin-left:0 !important;
// 	padding: 0;
// }
// ul.social-network li {
// 	display: inline;
// 	margin: 0 5px;
// 	a {
// 	 background-color: #B3B3B3;   
// 	}
// }


/* footer social icons */
// .social-network a.icoRss:hover {
// 	background-color: #F56505;
// }
// .social-network a.icoFacebook:hover {
// 	background-color:#3B5998;
// }
// .social-network a.icoTwitter:hover {
// 	background-color:#33ccff;
// }
// .social-network a.icoGoogle:hover {
// 	background-color:#BD3518;
// }
// .social-network a.icoVimeo:hover {
// 	background-color:#0590B8;
// }
// .social-network a.icoLinkedin:hover {
// 	background-color:#007bb7;
// }
// .social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
// .social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
// 	color:#fff;
// }
// a.socialIcon:hover, .socialHoverClass {
// 	color:#44BCDD;
// }

// .social-circle li a {
// 	display:inline-block;
// 	position:relative;
// 	margin:0 auto 0 auto;
// 	-moz-border-radius:50%;
// 	-webkit-border-radius:50%;
// 	border-radius:50%;
// 	text-align:center;
// 	width: 40px;
// 	height: 40px;
// 	font-size:20px;
// }
// .social-circle li i {
// 	margin:0;
// 	line-height:40px;
// 	text-align: center;
// }

// .social-circle li a:hover i, .triggeredHover {
// 	-moz-transform: rotate(360deg);
// 	-webkit-transform: rotate(360deg);
// 	-ms--transform: rotate(360deg);
// 	transform: rotate(360deg);
// 	-webkit-transition: all 0.2s;
// 	-moz-transition: all 0.2s;
// 	-o-transition: all 0.2s;
// 	-ms-transition: all 0.2s;
// 	transition: all 0.2s;
// }
// .social-circle i {
// 	color: #fff;
// 	-webkit-transition: all 0.8s;
// 	-moz-transition: all 0.8s;
// 	-o-transition: all 0.8s;
// 	-ms-transition: all 0.8s;
// 	transition: all 0.8s;
// }


// .box1 img,.box1:after,.box1:before{width:100%;transition:all .3s ease 0s}
// .box1 .icon,.box2,.box3,.box4,.box5 .icon li a{text-align:center}
// .box10:after,.box10:before,.box1:after,.box1:before,.box2 .inner-content:after,.box3:after,.box3:before,.box4:before,.box5:after,.box5:before,.box6:after,.box7:after,.box7:before{content:""}
// .box1,.box11,.box12,.box13,.box14,.box16,.box17,.box18,.box2,.box20,.box21,.box3,.box4,.box5,.box5 .icon li a,.box6,.box7,.box8{overflow:hidden}
// .box1 .title,.box10 .title,.box4 .title,.box7 .title{letter-spacing:1px}
// .box3 .post,.box4 .post,.box5 .post,.box7 .post{font-style:italic}


// .box3{box-shadow:0 0 3px rgba(0,0,0,.3);position:relative}
// .box3 .box-content,.box3:after,.box3:before{position:absolute;left:7%;right:7%;transition:all .3s}
// .box3:after,.box3:before{display:block;background:rgba(0,0,0,.3);top:10%;bottom:10%;z-index:1;transform:scale(0,1)}
// .box3:after{top:10.8%;bottom:10.8%;transform:scale(1,0)}
// .box3:hover:after,.box3:hover:before{transform:scale(1);animation:animate 1.5s}
// .box3:hover:before{border-top:3px solid #fff;border-bottom:3px solid #fff}
// .box:hover:after{border-left:3px solid #fff;border-right:3px solid #fff}
// .box img{width:100%;height:auto;transition:all .3s}
// .box3:hover img{transform:scale(1.2);filter:blur(5px);-moz-filter:blur(5px);-webkit-filter:blur(5px)}
// .box3 .box-content{padding:30px 10px;top:10%;bottom:10%;opacity:0;z-index:2}
// .box3:hover .box-content{box-shadow:0 0 0 35px rgba(255,255,255,.3);opacity:1;transition:all .3s}
// .box3 .title{font-size:24px;font-weight:600;color:#fff;margin:0 0 5px}
// .box3 .post{display:block;margin:0 0 5px;font-size:14px;color:rgba(255,255,255,.8)}
// .box3 .description{font-size:14px;color:#fff;margin:0 0 20px}
// .box3 .icon{padding:0;margin:0;list-style:none}
// .box3 .icon li{display:inline-block;margin:0 10px 0 0}
// .box3 .icon li a{display:block;width:30px;height:30px;line-height:30px;color:#fff;background:#e31e24;transition:all .5s}
// .box3 .icon a:hover{text-decoration:none;animation:animate-hover .5s;transition:all .3s}
// @keyframes animate{0%,100%{opacity:1}
// }
// @keyframes animate-hover{0%{box-shadow:0 0 0 10px rgba(255,255,255,.3)}
// 50%{box-shadow:0 0 0 5px rgba(255,255,255,.3)}
// 100%{box-shadow:0 0 0 0 rgba(255,255,255,.3)}
// }
// .box10,.box11,.box12,.box14,.box4,.box5,.box6,.box7,.box9{box-shadow:0 0 3px rgba(0,0,0,.3)}
// @media only screen and (max-width:990px){.box3{margin-bottom:30px}
// .box3 .box-content{padding:10px}
// .box3 .description{margin-bottom:10px}
// }
// @media only screen and (max-width:479px){.box3 .title{margin:0}
// }
.teamBx{
	padding:45px 0 65px 0;
	h2{
		position: relative;
		border-bottom:#ccc 1px solid;
		padding-bottom:10px;
		margin-bottom: 30px;
	}
	// h3:after {
 //    position: relative;
	// }
	h2:before {
	    content: ""; /* This is necessary for the pseudo element to work. */ 
	    display: block; /* This will put the pseudo element on its own line. */
	    margin: 0 auto; /* This will center the border. */
	    width: 15%; /* Change this to whatever width you want. */
	    padding-top: 20px; /* This creates some space between the element and the border. */
	    border-bottom: 2px solid #f00; /* This creates the border. Replace black with whatever color you want. */
	    position:absolute;
	    bottom:-1px;
	}
}
.our-team{
    overflow: hidden;
    position: relative;
}
.our-team:after{
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(43, 193, 234, 0.6);
    position: absolute;
    top: -100%;
    left: 0;
    opacity: 0;
    transition: all 0.8s ease 0s;
}
.our-team:hover:after{
    top: 0;
    opacity: 1;
}
.our-team img{
    width: 100%;
    height: auto;
}
.our-team .social{
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    position: absolute;
    top: -50%;
    left: 0;
    z-index: 1;
    transform: translateY(-50%) rotate(-12deg);
    transition: all 0.8s ease 0s;
}
.our-team:hover .social{ top: 50%; }
.our-team .social li{ display: inline-block; }
.our-team .social li a{
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #0b579f;
    font-size: 15px;
    color: #fff;
    margin: 0 5px;
}
.our-team .team-content{
    width: 100%;
    padding: 20px 25px;
    background: #fff;
    border-bottom: 3px solid #0b579f;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}
.our-team:hover .team-content{ background: #f9f9f9; }
.our-team .team-content:before{
    content: "";
    width: 100%;
    border-right: 420px solid #fff;
    border-top: 60px solid transparent;
    position: absolute;
    top: -60px;
    left: 0;
}
.our-team:hover .team-content:before{ border-right-color: #f9f9f9; }
.our-team .team-content:after{
    content: "";
    width: 3px;
    height: 50%;
    background: #0b579f;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.our-team .title{
    font-size: 18px;
    color: #333;
    margin: 0;
}
.our-team .post{
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: #888;
    margin-top: 3px;
}
@media only screen and (max-width: 990px){
    .our-team{ margin-bottom: 30px; }
}

.welcomeb{
	padding:35px 0;
	background:#f3f3f3;
.welcomeb2{
	margin: 0;
	
	// text-align: center;
	h2{
		text-transform: capitalize;
		color: #333;
	}
	p{
	// color: #333;
	// max-width: 100%;
	// margin: auto;
	// text-align: center;
	}
	.box3{
		margin-top: 25px;
	}
}
.newsBx{
	margin: 0;
	background: #fff;
	padding:10px 15px 15px 15px;
	// border:#ccc 1px solid;
	min-height: 400px;
	h3{
		position: relative;
		border-bottom:#ccc 1px solid;
		padding-bottom:10px;
	}
	// h3:after {
 //    position: relative;
	// }
	h3:before {
	    content: ""; /* This is necessary for the pseudo element to work. */ 
	    display: block; /* This will put the pseudo element on its own line. */
	    margin: 0 auto; /* This will center the border. */
	    width: 30%; /* Change this to whatever width you want. */
	    padding-top: 20px; /* This creates some space between the element and the border. */
	    border-bottom: 2px solid #f00; /* This creates the border. Replace black with whatever color you want. */
	    position:absolute;
	    bottom:-1px;
	}
	ul{
		margin: 0;
		padding:15px 0;
		li{
			display: block;
			// background:url(../images/arrow.png) no-repeat scroll 0 12px;
			transition: 0.3s; 
			padding:6px 6px;
			color:#454545;
			&:hover{
				i{
				color: #000;
			}
			}
			i{
				color: #454545;
			}
		}
	}
}
}
.accbox{
	background:#0074e1;
	padding:35px 0;
	margin: 35px 0;
	h2{
		color: #fff;
		text-align: center;
		padding:0 0 25px 0;
		// font-size: 26px;
	}
	.box1rs{
		background:#fff;
		margin: 15px 0;
		padding:10px;
		h3{
			font-size:18px;
			padding:15px 0 0 0 ;
			color: #811f22;
			font-weight: 600;
			margin: 0;
		}
		p{
			font-size: 14px;
		}
		span{
			font-size: 12px;
			color: #ccc;
			padding:8px 0;
			display:block;
		}
		img{
			width: 100%;
			height: 190px;
		}
		
	}
}
/* Underline From Center */
// .borderbx {
//   display: inline-block;
//   vertical-align: middle;
//   -webkit-transform: perspective(1px) translateZ(0);
//   transform: perspective(1px) translateZ(0);
//   box-shadow: 0 0 1px rgba(0, 0, 0, 0);
//   position: relative;
//   overflow: hidden;
// }
// .borderbx:before {
//   content: "";
//   position: absolute;
//   z-index: -1;
//   left: 51%;
//   right: 51%;
//   bottom: 0;
//   background: #2098D1;
//   height: 2px;
//   -webkit-transition-property: left, right;
//   transition-property: left, right;
//   -webkit-transition-duration: 0.3s;
//   transition-duration: 0.3s;
//   -webkit-transition-timing-function: ease-out;
//   transition-timing-function: ease-out;
// }
// .borderbx:hover:before, .borderbx:focus:before, .borderbx:active:before {
//   left: 0;
//   right: 0;
// }
.galleryBx{
	margin: 0;
	h2{
		position: relative;
		border-bottom:#ccc 1px solid;
		padding-bottom:10px;
	}
	// h3:after {
 //    position: relative;
	// }
	h2:before {
	    content: ""; /* This is necessary for the pseudo element to work. */ 
	    display: block; /* This will put the pseudo element on its own line. */
	    margin: 0 auto; /* This will center the border. */
	    width: 15%; /* Change this to whatever width you want. */
	    padding-top: 20px; /* This creates some space between the element and the border. */
	    border-bottom: 2px solid #f00; /* This creates the border. Replace black with whatever color you want. */
	    position:absolute;
	    bottom:-1px;
	}
	.box4{
		margin: 15px 0;
	}
	.box7{
		margin: 15px 0;
		border-radius:0;
		box-shadow:none;
		img{
			width: 100%;
			height:180px;
		}
	}
}

/*********************** Demo - 4 *******************/
// .box4{position:relative}
// .box4:before{width:0;height:200%;background:rgba(0,0,0,.5);position:absolute;top:0;left:-250px;bottom:0;transform:skewX(-36deg);transition:all .5s ease 0s}
// .box4:hover:before{width:200%}
// .box4 img{width:100%;height:150px}
// .box4 .box-content{width:100%;height:100%;padding-top:15%;position:absolute;top:0;left:0;transform:scale(0);transition:all .3s ease 0s}
// .box4 .icon,.box5 .icon{list-style:none;padding:0}
// .box4:hover .box-content{transform:scale(1)}
// .box4 .title{font-size:22px;font-weight:700;color:#fff;margin:0 0 10px}
// .box4 .icon{margin:0}
// .box4 .icon li{display:inline-block}
// .box4 .icon li a{display:block;width:35px;height:35px;line-height:35px;font-size:20px;background:#ee4266;color:#fff;margin-right:10px;transition:all .3s ease 0s}
// .box5 .icon,.box5 .icon li{display:inline-block}
// @media only screen and (max-width:990px){.box4{margin-bottom:30px}
// }
// @media only screen and (max-width:767px){.box4:before{left:-400px}
// .box4:hover:before{width:300%}
// }

// .counterbg{
// 	margin:25px 0;
// 	background:#641b50 url(../images/headbg.png);
// 	padding:35px 0;
// 	i{
// 		font-size:29px;
// 		color: #fff;
// 	}
// 	h2{
// 		text-align: center;
// 		color: #fff;
// 		padding-bottom:10px;
// 	}
// }
// .counter {
//     background:rgba(0,0,0,0.2);
//     padding: 20px 0;
//     border-radius: 5px;
//     color: #fff;
//     margin-top: 5px;
// }

// .count-title {
//     font-size: 30px;
//     font-weight: normal;
//     margin-top: 10px;
//     margin-bottom: 0;
//     text-align: center;
// }

// .count-text {
//     font-size: 13px;
//     font-weight: normal;
//     margin-top: 0px;
//     margin-bottom: 0;
//     text-align: center;
// }
.footerbx{
	margin:0;
	padding:25px 0;
	// border-top:#eee 2px solid;
	background:#0074e1;
	h3{
		color: #fff;
		padding-bottom:10px;
	}
	p{
		color: #fff;
	a{
		color: #fff;
	}	
	}
	ul{
		margin:0;
		padding:0;
		li{
			display:block;
			a{
				color: #fff;
				padding:2px 0;
				display:inline-block;
				&:hover{
					color: #fff;
				}
			}
		}
	}
	.social-network{
		li{
			display:inline-block;
			a{
				color: #000;
				i{
					color: #333
				}
			}
		}
	}
	
}
.foottop{
		text-align: center;
		padding:15px 0;
		p{
			margin:0;
			padding:0;
			a{
				color: #f00;
				&:hover{
					color: #254287;
				}
			}
		}
	}
.pagest{
	margin: 0;
	min-height: 425px;
	margin-bottom: 15px;
	h1{
		font-size:$h1Size;
		color: $blackC;
	}
	h3{
		font-size:$h1Size;
		color: $blackC;
		padding-bottom:15px;
	}
	iframe{
		margin:8px 0;
		// width: 100%;
		// height: 200px !important;
	}
}


.menu-area{background:#0074e1;}
.dropdown-menu{padding:0;margin:0;border:0 solid transition!important;border:0 solid rgba(0,0,0,.15);border-radius:0;-webkit-box-shadow:none!important;box-shadow:none!important}
.mainmenu a, .navbar-default .navbar-nav > li > a, .mainmenu ul li a , .navbar-expand-lg .navbar-nav .nav-link{
	color:#fff;
	font-size:14px;
	text-transform:uppercase;
	padding:10px 19px;
	font-family:'Roboto',sans-serif;
	display: block !important;
}
.mainmenu .active a,.mainmenu .active a:focus,.mainmenu .active a:hover,.mainmenu li a:hover,.mainmenu li a:focus ,.navbar-default .navbar-nav>.show>a, .navbar-default .navbar-nav>.show>a:focus, .navbar-default .navbar-nav>.show>a:hover{color: #fff;background: #753b6b; outline: 0;}
/*==========Sub Menu=v==========*/
.mainmenu .collapse ul > li:hover > a{background: #753b6b;}
.mainmenu .collapse ul ul > li:hover > a, .navbar-default .navbar-nav .show .dropdown-menu > li > a:focus, .navbar-default .navbar-nav .show .dropdown-menu > li > a:hover{background: #753b6b;}
.mainmenu .collapse ul ul ul > li:hover > a{background: #753b6b;}

.mainmenu .collapse ul ul, .mainmenu .collapse ul ul.dropdown-menu{background:#3c000a;}
.mainmenu .collapse ul ul ul, .mainmenu .collapse ul ul ul.dropdown-menu{background:#5f0917}
.mainmenu .collapse ul ul ul ul, .mainmenu .collapse ul ul ul ul.dropdown-menu{background:#801728}

/******************************Drop-down menu work on hover**********************************/
.mainmenu{background: none;border: 0 solid;margin: 0;padding: 0;min-height:20px;width: 100%;}
@media only screen and (min-width: 767px) {
.mainmenu .collapse ul li:hover> ul{display:block}
.mainmenu .collapse ul ul{position:absolute;top:100%;left:0;min-width:250px;display:none}
/*******/
.mainmenu .collapse ul ul li{position:relative}
.mainmenu .collapse ul ul li:hover> ul{display:block}
.mainmenu .collapse ul ul ul{position:absolute;top:0;left:100%;min-width:250px;display:none}
/*******/
.mainmenu .collapse ul ul ul li{position:relative}
.mainmenu .collapse ul ul ul li:hover ul{display:block}
.mainmenu .collapse ul ul ul ul{position:absolute;top:0;left:-100%;min-width:250px;display:none;z-index:1}

}
@media only screen and (max-width: 767px) {
.navbar-nav .show .dropdown-menu .dropdown-menu > li > a{padding:16px 15px 16px 35px}
.navbar-nav .show .dropdown-menu .dropdown-menu .dropdown-menu > li > a{padding:16px 15px 16px 45px}
}


// banner
.jssorb031 {position:absolute;}
.jssorb031 .i {position:absolute;cursor:pointer;}
.jssorb031 .i .b {fill:#000;fill-opacity:0.5;stroke:#fff;stroke-width:1200;stroke-miterlimit:10;stroke-opacity:0.3;}
.jssorb031 .i:hover .b {fill:#fff;fill-opacity:.7;stroke:#000;stroke-opacity:.5;}
.jssorb031 .iav .b {fill:#fff;stroke:#000;fill-opacity:1;}
.jssorb031 .i.idn {opacity:.3;}
// ****
.jssora051 {display:block;position:absolute;cursor:pointer;}
.jssora051 .a {fill:none;stroke:#fff;stroke-width:360;stroke-miterlimit:10;}
.jssora051:hover {opacity:.8;}
.jssora051.jssora051dn {opacity:.5;}
.jssora051.jssora051ds {opacity:.3;pointer-events:none;}
// end