@media all and (max-width: 1024px ){
.headright{
  display: none;
}
.box4{
  img{
    height: auto;
  }
}
.counter{
  margin:10px 0;
}
.footerbx{
  h3{
    margin-top: 15px;
  }
}
}
// i padd
@media (max-width: 768px){

}
// end

// i padd
@media (min-width: 664px) and (max-width: 768px) {

}
// end